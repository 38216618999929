import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import { Button, Dialog, Row, Col, MessageBox, Message, Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import i18n from '@/lang';
import global from '@/mixins/global';
import '@/styles/index.scss';
import { checkVersionAutoRefresh } from '@/utils/checkVersion';
import './lib/elementUi'

checkVersionAutoRefresh().then(() => {

    Vue.config.productionTip = false;

    Vue.use(Button)
    Vue.use(Dialog)
    Vue.use(Row)
    Vue.use(Col);
    Vue.use(Dropdown);
    Vue.use(DropdownItem);
    Vue.use(DropdownMenu);
    Vue.prototype.$message = Message;

    Vue.mixin(global);

    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app');
});
