import request from '../utils/request';

export const trackEvent = (data: any) => {
    return request({
        url: '/peanut/common/eventData/save',
        method: 'POST',
        data
    });
};

