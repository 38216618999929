import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

const MainLayout = () => import('../views/MainLayout.vue');
const HomePage = () => import('../views/official/agency/ph/HomePage.vue');
const OurBusiness = () => import('../views/official/agency/ph/OurBusiness.vue');
const OurService = () => import('../views/official/agency/ph/OurService.vue');
const Gallery = () => import('../views/official/agency/ph/Gallery.vue');
const AboutUs = () => import('../views/official/agency/ph/AboutUs.vue');


Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: MainLayout,
        children: [{
            path: '/',
            name: 'HomePage',
            component: HomePage
        }, {
            path: '/ourBusiness',
            name: 'OurBusiness',
            component: OurBusiness
        }, {
            path: '/ourService',
            name: 'OurService',
            component: OurService
        }, {
            path: '/gallery',
            name: 'Gallery',
            component: Gallery
        }, {
            path: '/aboutUs',
            name: 'AboutUs',
            component: AboutUs
        }]
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    next();
});
export default router;
