import Vue from 'vue';
import VueI18n from 'vue-i18n';

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en';
import elementThLocale from 'element-ui/lib/locale/lang/th';
import elementIdLocale from 'element-ui/lib/locale/lang/id';

// User defined lang
import enLocale from './en';
import thLocale from './th';
import idLocale from './id';

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale
    },
    th: {
        ...thLocale,
        ...elementThLocale
    },
    id: {
        ...idLocale,
        ...elementIdLocale
    }
    // 这里如果有其它语言包继续按照规则添加即可
};

export const getLocal = () => {
    let lang = ''
    if (navigator.language === 'id') {
        lang = 'id'
    } else {
        lang = 'en'
    }
    return localStorage.getItem('locale') || lang;
};

const i18n = new VueI18n({
    locale: getLocal(),
    messages
});

export default i18n;
