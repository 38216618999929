export default {
    landingWeb: {
        ok: 'ตกลง',
        submit: 'ส่ง',
        fieldRequired: 'จำเป็นต้องใส่ข้อมูลนี้',
        checkFormat: 'โปรดตรวจสอบเบอร์อีกครั้ง',
        checkNumberFormat: 'หมายเลขโทรศัพท์มือถือไม่ถูกต้อง! กรุณาตรวจสอบและลองอีกครั้ง!',
        verifySuccess: 'หัสยืนยันถูกส่งไปยังเบอร์มือถือของคุณ โปรดตรวจสอบ '
    },
    home: {
        eggpos: 'EGG Alai',
        subTitle: 'โปรแกรมสำหรับร้านอะไหล่รถยนต์ ',
        login: 'เข้าสู่ระบบ',
        freeTrial: 'ทดลองใช้ฟรี',
        en: 'EN',
        th: 'TH',
        getCode: 'รับรหัส',
        eggposIntro: 'Digital Transformation สำหรับร้านขายอะไหล่รถยนต์และผู้ค้าส่ง',
        eggposIntro2: 'เราได้รวบรวมวิธีการพัฒนาการขายอะไหล่ทั้งออฟไลน์และออนไลน์ให้มีประสิทธิภาพมากขึ้น รวมถึงระบบบริหารและการตลาดที่เหนือกว่าเดิม',
        eggposTitle1: 'EGG Alai - ผู้ช่วยเพิ่มศักยภาพให้ร้านค้าของคุณก้าวไปอีกขั้น',
        eggposSubTitle1: 'เพิ่มศักยภาพการขายอะไหล่ทั้งออฟไลน์และออนไลน์ พร้อมระบบจัดการและการตลาดที่เหนือกว่าเดิม',
        introTitle1: 'การขายออฟไลน์และออนไลน์',
        productSearch: 'ค้นหาอะไหล่เพียงแค่ใช้ VIN หรือรุ่นรถ',
        productSearchIntro: 'ข้อมูลอะไหล่แท้และเทียบจากรุ่นรถยอดนิยมในไทย รวมถึงการอัปเดทราคาในวินาทีเดียว',
        autoPart: 'ข้อมูลอะไหล่กว่าล้านรายการ พร้อมราคาอัปเดทล่าสุด',
        autoPartIntro: 'ใช้ข้อมูลเราสร้างอะไหล่เข้าร้านได้เพียงไม่กี่คลิก',
        customerCredit: 'บริหารเครดิตให้ลูกค้าอย่างประทับใจ ',
        customerCreditIntro: 'ตรวจสอบข้อมูล เช็ควงเงิน สร้างใบวางบิล และอีกมากมาย',
        smartFeature: 'ระบบสแกนข้อมูลล้ำสมัย',
        smartFeatureIntro: 'จัดการและขายสินค้าด้วยบาร์โคดอย่างรวดเร็ว',
        introTitle2: 'การบริหารลูกค้าสัมพันธ์บนมือถือ',
        customerManagement: 'การบริหารข้อมูลลูกค้า',
        customerManagementIntro: 'สร้าง/แก้ไข/ลบ ข้อมูลลูกค้า',
        mobileCrm: 'ระบบบริหารลูกค้าได้ทุกที่ทุกเวลา',
        mobileCrmIntro: 'พนักงานขายสามารถเข้าถึงและอัปเดทข้อมูลลูกค้าได้ตลอดเวลาผ่านมือถือเมื่อเข้าพบ',
        onsightOrdering: 'สร้างออเดอร์ได้ทันทีเมื่อเข้าพบลูกค้า',
        onsightOrderingIntro: 'พนักงานขายสามารถสร้างออเดอร์ให้ลูกค้าได้ทันทีเพียงแค่เข้าแอป',
        saleForce: 'ตอบโจทย์การบริหารทีมขายได้ทันที',
        saleForceIntro: 'มอบหมายงาน เช็คการเข้าพบลูกค้า ตำแหน่ง GPS รวมถึงรายงานการพบลูกค้า ได้แบบ 24/7',
        introTitle3: 'การจัดการสินค้าคงคลังและการจัดซื้อ',
        purchaseProcess: 'ระบบจัดซื้อ',
        purchaseProcessIntro: 'ระบบการซื้อสินค้ากับผู้แทนจำหน่ายอย่างมีประสิทธิภาพ',
        inventoryManage: 'ระบบคลังสินค้า',
        inventoryManageIntro: 'บริหารโกดังสินค้าง่ายแค่ปลายนิ้ว',
        logistic: 'ตรวจสอบสถานะการส่งสินค้ากับบริษัทขนส่ง',
        logisticIntro: 'สามารถตรวจสอบสถานะการส่งสินค้าแบบเรียลไทม์ผ่านหน้าจอ',
        scheduleBooking: 'จองและเรียกใช้บริการขนส่งภายนอก',
        scheduleBookingIntro: 'เพื่อความสะดวกในการส่งสินค้า อีกทั้งสามารถพิมพ์ใบส่งสินค้าได้ทันที',
        introTitle4: 'แผงสรุปข้อมูล และรายงานทางบัญชี',
        businessOverview: 'สรุปรายงานภาพรวมธุรกิจ',
        businessOverviewIntro: 'รวมข้อมูลสำคัญเช่น ยอดขาย มูลค่าสินค้า สต็อค ฯลฯ มาไว้ในหน้าเดียว',
        profitLoss: 'งบกำไร-ขาดทุน',
        profitLossIntro: 'ตรวจสอบกำไรในคลิกเดียว รวมถึงอัตราส่วนทางการเงินที่สำคัญเพื่อให้คุณวางแผนธุรกิจได้ง่ายขึ้น',
        createCollection: 'สร้างข้อมูลใบวางบิล',
        createCollectionIntro: 'สรุปรายงานและพิมพ์ใบวางบิลให้ลูกค้าได้ทันที',
        generateBusiness: 'สร้างรายงานบัญชีภาษี',
        generateBusinessIntro: 'บริการสร้างรายงานสรุปภาษีเพื่อส่งสรรพากรใน 1 วินาที',
        introTitle5: 'เชื่อม API กับบุคคล/บริษัทภายนอก',
        connectToOther: 'เชื่อมต่อกับแพลตฟอร์มขายอื่นๆ (Shopee,Lazada)',
        connectToOtherIntro: 'นำข้อมูลสินค้าจากร้านในระบบขึ้นขายบนแพลตฟอร์มอื่นได้ในเวลาไม่กี่นาที',
        customerOrder: 'ระบบสั่งซื้อผ่าน APP/เว็บ',
        customerOrderIntro: 'ลูกค้าของคุณสามารถสั่งซื้อร้านคุณผ่านเว็บหรือแอปได้อย่างสะดวก',
        paymentGateway: 'ชื่อมต่อระบบชำระเงินออนไลน์',
        paymentGatewayIntro: 'ตั้งค่าระบบให้ลูกค้าสามารถชำระเงินได้ด้วยบัตรเครดิต',
        facebookAds: 'ระบบจัดการโฆษณาออนไลน์ผ่าน google/facebook',
        facebookAdsIntro: 'ทำการตลาดออนไลน์ให้ร้านคุณง่ายแค่หยิบมือด้วยระบบนี้',
        eggPosFreeTrial: 'ทดลองใช้ EGG Alai ฟรี',
        eggposTitle2: 'ทำไมต้อง EGG Alai ?',
        eggposSubTitle2: 'ด้วยฟังก์ชั่นที่ครอบคลุมและใช้งานง่ายสำหรับทุกคน ที่มาพร้อมเทคโนโลยีล่าสุดในการวิเคราะห์ข้อมูลผ่านระบบ Cloud',
        serverIntro: 'มั่นใจได้กับเซิฟเวอร์ระดับโลกจาก Alibaba Cloud ที่จะปกป้องข้อมูลของคุณอย่างปลอดภัยที่สุดและพร้อมใช้งานได้ตลอดเวลา',
        eggposTitle3: 'เลือกแพ็คเกจ EGG Alai ที่เหมาะสมกับความต้องการของคุณ ',
        eggposSubTitle3: 'หรือทดลองใช้ฟรีกับแพ็คเกจใดๆ',
        solo: 'SOLO',
        baht: 'บาท',
        perMonth: '3 เดือน',
        fiveUsers: '5 ผู้ใช้งาน',
        twoGStorage: 'ขนาดความจุ 2.0 GB',
        fiveThousandTransactions: '5000 ธุรกรรม/ เดือน',
        fiveThousandPerMonth: '5000 / เดือน',
        advance: 'Advance',
        fifteenUsers: '15 ผู้ใช้งาน',
        threeGStorage: 'ขนาดความจุ 3.0 GB',
        unlimitedTransaction: 'ไม่จำกัดธุรกรรม',
        online: 'Online',
        thirtyUsers: '30 ผู้ใช้งาน',
        fourGStorage: 'ขนาดความจุ 4.0 GB',
        master: 'Master',
        fiftyUsers: '50 ผู้ใช้งาน',
        fiveGStorage: 'ขนาดความจุ 5.0 GB',
        dataSubscribtion: '*ไม่รวมค่าบริการ Data Subscription และ บริการเสริม Pay by use (ราคาไม่รวมภาษีมูลค่าเพิ่ม)',
        comparePackage: 'เปรียบเทียบแพ็คเกจ EGG Alai',
        askQuestions: 'คำถามที่พบบ่อย',
        q1: 'Data Subscription คืออะไร ?',
        a1: 'การสมัครรับข้อมูลจะช่วยให้เจ้าของร้านขายอะไหล่รถยนต์สามารถเพลิดเพลิน กับการแจ้งอัปเดตราคา OE โดยอัตโนมัติ การถอดรหัสอะไหล่จาก VIN (เลขตัวถังรถ) การค้นหา OE SKU และการค้นหา SKU อะไหล่ทดแทน ค่าธรรมเนียมการสมัครข้อมูลจะถูกเรียกเก็บทุกไตรมาส (ในกรณีเจ้าของร้านสมัครใช้งานฟีเจอร์นี้) ในอัตรา 0.1% ของยอดขาย OE ทั้งหมดในไตรมาสปัจจุบัน',
        q2: 'จ่ายตามการใช้งานจริง (Pay by Use) คืออะไร ?',
        a2: 'บริการนี้เป็นบริการเสริมที่เปิดให้เจ้าของร้านอะไหล่รถยนต์เลือกใช้ได้ตามที่ต้องการ โดยจะมีการเรียกเก็บในเดือนที่เรียกใช้ยกตัวอย่างเช่น เลือกและจอง บริการขนส่งต่างๆผ่าน EGG Alai การสร้างรายงานภาษีรายเดือนเพื่อส่งสรรพากรเพียงคลิกเดียว การจัดการโฆษณาผ่านโซเชียลมีเดีย',
        q3: 'ค่าใช้จ่ายทั้งหมดของร้านหากใช้ EGG Alai จะเป็นเท่าไร ?',
        a3: 'คส 1 – ร้านอะไหล่ A ร้านอะไหล่ A ใช้บริการ EGG Alai “Solo” ในเดือนกันยายน 2564 โดยใช้ทำธุรกรรมไปทั้งสิ้น 4 ล้านบาทต่อเดือน (โดยมียอดขาย OE 50%) ดังนั้นค่าใบอนุญาตจะอยู่ที่ 300 บาท ต่อเดือน และถ้าร้านลงทะเบียนใช้  Data subscription (สมัครใช้งาน Data) ค่าบริการส่วนนี้จะอยู่ที่ 2,000 บาท ต่อเดือน',
        q4: 'จะมั่นใจในระบบความปลอดภัยของระบบและข้อมูลส่วนตัวได้อย่างไร ?',
        a4: 'เราใช้บริการจาก Alibaba Cloud ซึ่งมีความปลอดภัยและมั่นคงที่สูงระดับโลก และเรามีฟังก์ชั่นการแยกสิทธิ์เข้าถึงข้อมูลของพนักงานเพื่อให้เจ้าของร้านมั่นใจในการจัดการข้อมูล',
        carouselTitle1: 'เจ.ที. อะไหล่ยนต์',
        carouselContent1: '“เมื่อก่อนผมใช้ระบบ POS ตั้งโต๊ะทั่วไปซึ่งมีความยากที่จะหาข้อมูลอะไหล่จากในร้าน พอได้รู้จัก EGG Alai ก็เหมือนได้ตัวช่วยในการหาอะไหล่กว่า 100,000 เบอร์ในร้านได้เร็วขึ้นและบริการลูกค้าได้มากขึ้น”',
        carouselTitle2: 'ก. อะไหล่ยนต์',
        carouselContent2: '“ที่ร้านหาซอฟท์แวร์ที่ใช้งานง่ายที่สามารถช่วยเรื่องสต็อคสินค้าและบริหารการซื้อของเข้าร้าน ตอนนี้เรามี EGG Alai เพื่อช่วยจัดการตำแหน่งวางสินค้าในโกดังให้ดูเป็นระเบียบขึ้นและบริหารการเติมสินค้า ตอนนี้ที่ร้านใช้เวลาทำงานน้อยลง และลดต้นทุนหลายๆอย่าง เยี่ยมเลย”',
        carouselTitle3: 'ว. เจริญกลการ',
        carouselContent3: '“ผมใช้ EGG Alai ในการเชื่อมต่อกับบริการขนส่งภายนอกรวมถึงเชื่อมกับแอป Lazada มันง่ายมากๆในการขายของออนไลน์”',
        whatsEgg: 'WhatsEGG',
        companyIntro: 'ก่อตั้งขึ้นในปี 2017, Whats EGG เป็นแพลตฟอร์มชั้นนำในการแก้ไขปัญหาในส่วนหลังการขายรถยนต์สำหรับ B2B ในภูมิภาคอาเซียน เราให้บริการโซลูชั่นแบบดิจิตอลสำหรับอู่ซ่อมรถ, ร้านอะไหล่, ร้านค้าปลีกค้าส่งอะไหล่และบริษัทประกันภัยยานพาหนะ',
        productLinks: 'ลิงค์สินค้า',
        eggmall: 'EGG Mall',
        eggRepair: 'EGG Repair',
        scp: 'SCP',
        freeTrialDlgTitle: 'ฉันสนใจ โปรดติดต่อกลับ',
        mobileNumber: 'เบอร์มือถือ',
        mobileNumberPlaceholder: 'โปรดกรอกเบอร์มือถือ',
        verifyCode: 'รหัสยืนยัน',
        verifyCodePlaceholder: 'กรอกรหัสยืนยัน 4 ตัว',
        send: 'ส่ง',
        resend: 'Resend',
        shopName: 'ชื่อร้าน',
        shopNamePlaceholder: 'โปรดกรอกชื่อร้านของคุณ',
        contactPerson: 'ผู้ติดต่อ',
        contactPersonPlaceholder: 'โปรดใส่ชื่อผู้ติดต่อของคุณ',
        shopAddress: 'ที่อยู่ร้าน',
        shopAddressPlaceholder: 'โปรดกรอกที่อยู่ร้านของคุณ',
        email: 'อีเมล์',
        emailPlaceholder: 'โปรดกรอกอีเมล์ของคุณ',
        submitSuccess: 'ส่งข้อมูลเรียบร้อย !',
        submitIntro: 'ส่งข้อมูลติดต่อของคุณเรียบร้อยแล้ว พนักงานของเราจะติดต่อเพื่อให้บริการคุณโดยไวที่สุด',
        IamInterested: 'ฉันสนใจ',
        pricing: 'ราคา',
        footerTips: 'ฉันสนใจ โปรดติดต่อกลับ',
        next: 'วีดีโอถัดไป',
        replay: 'เล่นอีกครั้ง',
        clickPlay: 'คลิกเพื่อดูวีดีโอเพิ่มเติม',
        customerVideoTitle: ' เสียงจากผู้ใช้งานจริง: วรรณอะไหล่ยนต์',
        contact_us: 'ติดต่อเรา',
        hotline: 'สายด่วน',
        line: 'จำนวนรายการ',
        facebook: 'เฟซบุ๊ก',
        homeBannerTitle: 'เราทำให้เรื่องซ่อมรถเป็นเรื่องง่าย',
        homeBannerDesc: 'ก่อตั้งขึ้นในปี 2560, Whats Egg เป็นโซลูชันแพลตฟอร์ม B2B ชั้นนำในอาเซียนโดยมุ่งเน้นไปที่การขายหลังการขายยานยนต์',
        homeEggmallTitle: 'เพิ่มโอกาสการขายของคุณในยุคดิจิทัลกับ EGGMall',
        homeEggmallDesc: '· ให้ธุรกิจเติบโตในตลาดออนไลน์ได้ทุกวันตลอด 24 ชั่วโมง\n· เข้าถึงตลาดยานยนต์ในกลุ่มลูกค้าธุรกิจได้อย่างแม่นยำ\n· ค้นหาชิ้นส่วนอะไหล่แบบดิจิทัลโดยระบบ Search Engine',
        homeEggRepairTitle: 'บริหารจัดการอู่ของคุณได้สะดวกง่ายดาย',
        homeEggRepairDesc: 'แอพพลิเคชั่น EGG Repair เป็นระบบแอพพลิเคชั่น การเซอร์วิสและบริหารจัดการสำหรับอู่ซ่อมรถที่สมบูรณ์แบบ โดยจะครอบคลุมทุกกระบวนการทำงานของอู่ซ่อมรถทั้งหมด เช่น การออกใบเสร็จชำระเงิน,การบริหารจัดการลูกค้า, การจัดซื้อจัดหาอะไหล่, การจัดการคลังสินค้า, การจัดการเรื่องการเงิน'
    },
    package: {
        packageFeature: 'แพคเกจ EGG Alai Feature',
        expandAll: 'ขยาย',
        foldAll: 'ย่อ',
        package: 'แพ็คเกจ',
        monthly: '3 เดือน',
        sixMonths: '6 เดือน',
        numberOfUsers: 'จำนวนบัญชีผู้ใช้งาน',
        storageSpace: 'ขนาดความจุข้อมูล',
        transactions: 'จำนวนธุรกรรม',
        dataFreeSupport: 'การสนับสนุนด้านข้อมูลในระบบ',
        maximumSkuUploaded: 'จำนวนรายการสินค้าที่ใส่ในระบบได้สูงสุด',
        maximumSkuExport: 'จำนวนรายการสินค้าที่อนุญาตให้ส่งออกและปรับปรุงรายการได้สูงสุดต่อครั้ง',
        iamSkuFreeSelection: 'จำนวนรายการสินค้าที่สามารถนำเข้าระบบเพื่อเปิดใช้งานครั้งแรกโดยไม่มีค่าใช้จ่าย',
        freeTransactionExport: 'รายการส่งออกข้อมูลสูงสุดต่อครั้งโดยไม่มีค่าใช้จ่าย',
        basicFunction: 'ฟังก์ชั่นพื้นฐาน',
        basicFeature1: 'แสดงโลโก้ร้าน',
        basicFeature2: 'นำเข้า/ส่งออก ข้อมูลสินค้าผ่าน excel',
        basicFeature3: 'การกำหนดบทบาทของผู้ใช้งาน',
        basicFeature4: 'แก้ไขติดตามประวัติการใช้งาน',
        basicFeature5: 'สร้างร้านค้าผ่าน EGGMall อัตโนมัติ',
        basicFeature6: 'การตั้งค่าการพิมพ์เอกสารแบบมาตรฐาน',
        basicFeature7: 'การตั้งค่าพิมพ์บาร์โค้ดแบบมาตรฐาน',
        basicFeature8: 'การตั้งค่าพิมพ์เอกสารแบบยืดหยุ่น',
        basicFeature9: 'การตั้งค่าพิมพ์บาร์โค้ดแบบยืดหยุ่น',
        counterSales: 'ขายผ่านหน้าเค้าเตอร์',
        counterFeature1: 'ค้นหาสินค้าด้วยหมายเลขตัวถังรถหรือประเภทของยานพาหนะ',
        counterFeature2: 'สร้างใบเสนอราคา สร้างการสั่งซื้อและมัดจำ',
        counterFeature3: 'คัดลอกใบสั่งซื้อ',
        counterFeature4: 'การจัดการการคืนสินค้า',
        counterFeature5: 'ฐานข้อมูลชิ้นส่วนยานยนต์และราคาตั้งของสินค้า',
        counterFeature6: 'การจัดการเครดิตลูกค้า',
        counterFeature7: 'การตั้งรหัสราคาให้กับลูกค้าที่แตกต่างกัน',
        counterFeature8: 'การสร้างหมายเลขซีเรียลและบาร์โค้ด',
        counterFeature9: 'การสแกนบาร์โค้ดสินค้า',
        mobileFeature1: 'การบริหารจัดการลูกค้า',
        mobileFeature2: 'ระบบบริหารลูกค้าได้ทุกที่ทุกเวลา',
        mobileFeature3: 'การสั่งซื้อผ่านพนักงานขายขณะเข้าพบลูกค้า',
        mobileFeature4: 'การจัดการบริหารพนักงานขาย',
        mobileFeature5: 'โปรแกรมสะสมแต้มสำหรับลูกค้า',
        mobileFeature6: 'SMS แจ้งเตือนให้กับลูกค้า',
        purchasingLogistic: 'ระบบการจัดซื้อและขนส่ง',
        purchaseFeature1: 'ระบบการจัดซื้อสินค้า',
        purchaseFeature2: 'การจัดการสินค้าคงคลังพร้อมรายงานสรุป',
        purchaseFeature3: 'ระบบหาสถานที่ตั้งของสินค้าในคลัง',
        purchaseFeature4: 'ระบบจองตารางเวลาการขนส่ง',
        purchaseFeature5: 'ตรวจสอบสถานะการขนส่ง',
        dashboardFeature1: 'แดชบอร์ดแสดงภาพรวมของธุรกิจ',
        dashboardFeature2: 'รายงานกำไรขาดทุน',
        dashboardFeature3: 'สร้างกลุ่มบิลสำหรับเรียกเก็บเงินลูกค้าเครดิต',
        dashboardFeature4: 'สร้างรายงานแสดงธุรกิจและภาษี',
        dashboardFeature5: 'เอกสารภาษี / ใบแจ้งหนี้',
        dashboardFeature6: 'รายงานสรุปการขาย',
        apiFeature1: 'เชื่อมต่อไปยังตลาดอื่น ๆ (Lazada, Shopee ... )',
        apiFeature2: 'เชื่อมต่อรายการสินค้าและคำสั่งซื้อของลูกค้าผ่านทางแอพและเว็บ',
        apiFeature3: 'ติดตั้งโปรโมชั่นส่งเสริมการขาย',
        apiFeature4: 'การเชื่อมต่อระบบรับการชำระเงิน',
        apiFeature5: 'เชื่อมต่อกับ Facebook และร้าน Google',
        apiFeature6: 'การจัดการโฆษณาบน Facebook และ Google',
        unlimited: 'ไม่จำกัด',
        inventoryManagementPurchasing: 'การจัดการสินค้าคงคลังและการจัดซื้อ',
        dashboardFeature7: 'บันทึกรายการบัญชีเจ้าหนี้ ลูกหนี้ และบัญชีแยกประเภท อัตโนมัติ',
        counterFeature10: 'การอัพเดทข้อมูลสินค้าแบบเรียลไทม์',
        counterFeature11: 'การแก้ไขสต๊อคสินค้าจากหน้าคำสั่งซื้อ',
        counterFeature12: 'การสแกนบาร์โค้ดและคิวอาร์โค้ดสินค้า',
        counterFeature13: 'พิมพ์ใบแจ้งหนี้ ใบเสร็จ และใบส่งของอย่างง่ายดาย',
        counterFeature14: 'การตั้งค่าส่วนลดตามปริมาณการขาย',
        counterFeature15: 'การพิมพ์ใบเสนอราคาแบบพื้นฐาน',
        featurePackage: 'แพคเกจการใช้งานฟีเจอร์',
        addedOnFeature: 'ฟีเจอร์เสริม',
        salesManagementName: 'การจัดการงานขาย',
        salesManagementName1: 'การตั้งค่าการเตือนสำหรับกำไรขั้นต่ำ',
        salesManagementName2: 'การค้นหาอะไหล่แท้และสร้างสินค้าและรายการขายอัตโนมัติ',
        salesManagementName3: 'การค้นหาอะไหล่เทียบและสร้างสินค้าและรายการขายอัตโนมัติ',
        salesManagementName4: 'การเชื่อมต่อข้อมูลจาก EMCS เข้าสู้รายการขายบน EGG Alai ',
        salesManagementName5: 'การจัดการงานขายประกันภัย',
        salesManagementName6: 'การตัดสต๊อคอัตโนมัติจากการเชื่อมต่อ Lazada/Shopee API ',
        salesManagementName7: 'การพิมพ์ AWB หลายรายการพร้อมกันจากการเชื่อมต่อ Lazada/Shopee API',
        salesManagementName8: 'การรับชำระด้วย QR code',
        salesManagementName9: 'ระบบบริหารจัดการใบเสนอราคา',
        purchasingManagementName: 'การจัดการงานจัดซื้อ',
        purchasingManagement1: 'ระบบวางแผนการจัดซื้อ',
        productInventoryManagementName: 'จัดการสินค้า',
        productInventoryManagement1: 'ประวัติของสินค้า',
        productInventoryManagement2: 'แจ้งเตือนและปรับราคาอะไหล่แท้อัตโนมัติ',
        productInventoryManagement3: 'การนำเข้าและอัพเดทสินค้าคงคลัง',
        productInventoryManagement4: 'ดึงข้อมูลอะไหล่จาก Lazada/Shopee นำเข้าระบบ Egg alai',
        reportDashboardName: 'รายงานสรุปและการส่งออกข้อมูล',
        reportDashboard1: 'บริการส่งออกข้อมูลในระบบเพิ่มเติม',
        reportDashboard2: 'รายงานสรุปเชิงธุรกิจ',
        additionalDataManagementName: 'การจัดการข้อมูลอะไหล่บนระบบ',
        additionalDataManagement1: 'บริการเพิ่มข้อมูลอะไหล่ทดแทนจากรายการสินค้าของ EGG Mall ทุกๆ 2,000 SKU',
        additionalDataManagement2: 'บริการย้ายข้อมูลอะไหล่จากระบบเก่าเข้าสู่ EGG Alai ทุกๆ 2,000 SKU (จำกัดสินค้าที่ 20 หมวดหมู่)',
        contactTip: '· ต้องการทราบรายละเอียดและค่าใช้จ่ายเพิ่มเติมของฟีเจอร์เสริม'
    },
    qanda: {
        title: 'คำถามที่พบบ่อย'
    },
    EGGMall: {
        partner_center: 'Seller Center',
        set_up_shop: 'สนใจเปิดร้าน',
        welcome_text: 'ยินดีต้อนรับสู่พาร์ทเนอร์เซ็นเตอร์',
        agree: 'ยอมรับ',
        not_agree: ' ไม่ยอมรับ',
        data_usage_agreement: 'ข้อตกลงการใช้งานข้อมูล',
        why_choose_us: 'ทำไมต้อง EGG Mall?',
        specific_description: 'เพราะเราสามารถวิเคราะห์ข้อมูลได้อย่างครบถ้วน ด้วยเทคโนโลยีคลาวด์ ที่ง่ายต่อการจัดการภายในร้าน',
        benifits: 'สิทธิประโยชน์',
        special_search_feature: 'ค้นหาอะไหล่ได้ง่ายด้วยฟังก์ชั่นพิเศษ',
        search_by_vin: 'ค้นหาสินค้าได้ด้วยเลข VIN หรือ ค้นหาตามรุ่นรถ',
        search_by_customer: 'ช่วยลดโอกาสเกิดความผิดพลาดในการสั่งซื้อสินค้า',
        digital_marketing_environment_title: 'โปรโมทร้านของคุณให้น่าสนใจ',
        digital_marketing_environment: 'การทำ Digital Marketing',
        ecosystem: 'ระบบนิเวศ',
        social_reels: 'Reels, Online Ads, บทความสาระน่ารู้, CPAs Ad, สื่อสังคมออนไลน์',
        marketing_management: 'การจัดการการตลาด',
        facebook_page: 'Social Media, สื่อสิ่งพิมพ์, SMSs, ข่าวประชาสัมพันธ์',
        market_focusing_title: 'การมุ่งตลาดเฉพาะส่วน',
        market_focusing: 'กำหนดเป้าหมายเฉพาะส่วน',
        offline_sales: 'เน้นกลุ่มเป้าหมายที่ตรงกับฐานลูกค้าหน้าร้าน ให้ตรงความต้องการในแต่ละภาคส่วนของอุตสาหกรรม เช่น เจ้าของอู่ เจ้าของรถ หรือตัวแทนจำหน่าย เป็นต้น',
        one_stop_service: 'One-Stop Service',
        e_commerce: 'มีระบบจัดการร้านอะไหล่ และอู่ซ่อมรถ ครอบคลุมกลุ่มธุรกิจยานยนต์ ภายในแพลตฟอร์มเดียว',
        frequently_asked_questions: 'คำถามที่พบบ่อย',
        dialogue1: 'รู้ไหมว่าเปิดร้าน EGGShop ภายในปีนี้ ไม่ต้องจ่ายสักบาท!',
        dialogue2: 'จริงดิ!',
        dialogue3: ' ใช่! ฟรีค่าธรรมเนียมแรกเข้า ค่าธรรมเนียมรายปี แม้แต่แพ็คเกจการตลาดก็ฟรีด้วยนะ',
        dialogue4: 'แล้วได้สิทธิประโยชน์อะไรบ้างละ?',
        dialogue5: 'ก็มีทีมงานช่วยเนมิตร้านค้าออนไลน์ แถมยังโปรโมทสินค้าให้ด้วย หมดกังวลได้เลย เพราะมีเจ้าหน้าที่ผู้เชี่ยวชาญคอยแก้ไขปัญหาให้ตลอดอายุการใช้งาน',
        whats_egg_description: 'ก่อตั้งขึ้นในปี 2560, Whats Egg เป็นโซลูชันแพลตฟอร์ม B2B ชั้นนำในอาเซียนโดยมุ่งเน้นไปที่การขายหลังการขายยานยนต์ เราให้บริการโซลูชั่นดิจิตอลสำหรับการประชุมเชิงปฏิบัติการผู้ค้าปลีกผู้ค้าส่งและ บริษัท ประกันภัยยานพาหนะ',
        product_links: 'ลิงค์สินค้า',
        APP_download: 'ดาวน์โหลดแอพ',
        please_contact_us: 'กรุณาติดต่อเรา',
        learn_more: 'เรียนรู้เพิ่มเติม',
        generate_solution: 'สร้างโซลูชัน',
        set_up_shop_title: 'กรอกข้อมูลเพื่อให้เจ้าหน้าที่ติดต่อกลับ',
        mobileNumberPlaceholder: 'กรอกหมายเลขโทรศัพท์มือถือของคุณ',
        verifyCodePlaceholder: 'กรอกรหัส 4 หลัก',
        shopNamePlaceholder: 'กรอกชื่อร้านค้าของคุณ',
        shopAddressPlaceholder: 'กรอกหมายเลขถนนและบ้าน',
        emailPlaceholder: 'กรอกอีเมล์ของคุณ',
        verifyCode: 'รหัส OTP',
        shopAddress: 'ที่อยู่ร้านค้า',
        checkFormat: 'โปรดตรวจสอบรูปแบบ',
        getCode: 'รับรหัส',
        submitIntro: 'ข้อมูลของคุณถูกส่งเรียบร้อยแล้วพนักงานของเราจะติดต่อคุณโดยเร็วที่สุด',
        submitSuccess: 'ส่งสำเร็จ!',
        contactPersonPlaceholder: 'โปรใส่ข้อมูลผู้ติดต่อของคุณ',
        send_code_success: 'รหัสยืนยันถูกส่งไปยังมือถือของคุณแล้ว โปรดตรวจสอบข้อความ',
        banner_title: 'เพิ่มโอกาสการขายของคุณในยุคดิจิทัลกับ EGGMall',
        banner_desc_1: 'ให้ธุรกิจเติบโตในตลาดออนไลน์ได้ทุกวันตลอด 24 ชั่วโมง',
        banner_desc_2: 'เข้าถึงตลาดยานยนต์ในกลุ่มลูกค้าธุรกิจได้อย่างแม่นยำ',
        banner_desc_3: 'ค้นหาชิ้นส่วนอะไหล่แบบดิจิทัลโดยระบบ Search Engine',
        contact_us_to_create_shop: 'ติดต่อเราเพื่อสร้างร้านของคุณ',
        why_choose_EGGMall: 'ทำไมต้อง EGGMall',
        how_to_apply: 'สมัครเป็นผู้ขาย',
        it_support: 'ผู้เชี่ยวชาญด้านระบบ IT',
        marketing_support: 'การสนับสนุนทางการตลาด',
        seller_benefits: 'สิทธิประโยชน์สำหรับผู้ขาย',
        commission_free: 'ค่าคอมมิชชั่นเริ่มต้นที่ 0%',
        register_free: 'ฟรีค่าสมัคร',
        special_transferred_seller: 'พิเศษ! สำหรับผู้ขายที่ย้ายแพลตฟอร์ม \n รับเครดิตส่งเสริมการขาย มูลค่า 5,000 บาท',
        annual_fee: 'ฟรีค่าธรรมเนียมรายปี',
        special_settlement_fee: '*อัตราพิเศษสำหรับค่าธรรมเนียม settlement',
        monthly_promotional_campaign: 'แคมเปญส่งเสริมการขายประจำเดือน',
        platform_promotional_cash: 'แคชแบ็คและเงินสนับสนุนด้านการขายสูงสุดถึง 4%',
        applicable_under_TC: '**เงื่อนไขเป็นไปตามบริษัทกำหนดของปี 2566',
        easy_to_apply: 'สมัครง่ายๆ',
        fill_the_form: '1.เพียงแค่กรอกข้อมูลในแบบฟอร์ม',
        waiting_contact: '2.รอเจ้าหน้าที่ EGGMall ติดต่อกลับ',
        lifetimeservice_support: 'มีทีมช่วยเหลืองานบริการหลังการขายตลอดอายุการใช้งาน',
        east_to_transfer_data: 'ถ้าผู้ขายมีร้านอยู่บน Shopee หรือ Lazada สามารถโอนข้อมูลร้านมาที่ EGGMall ได้เลย',
        data_management_team_support: 'มีทีมช่วยดูแลจัดการข้อมูลให้ตลอดอายุการใช้งาน',
        reach_the_right_target: 'เข้าถึงกลุ่มเป้าหมาย ',
        promote_your_shop_via_EGGMall: 'และโปรโมทร้านของคุณผ่านแอพพลิเคชั่นและเว็ปไซต์ EGG Mall',
        enlarge_your_customer: 'ขยายฐานลูกค้า',
        base_by_social_media: 'โดยการโปรโมทบน Social Media',
        year_round_campaign: 'มีแคมเปญส่งเสริม',
        sale_booster: 'การขายตลอดปี',
        seller_on_board_promotion: 'โปรโมชันส่งเสริมการขายสำหรับร้านค้าเปิดใหม่',
        app_open_pop_up: 'แอพพลิเคชั่น-หน้า pop-up',
        homepage_banner_app_Website: 'แบนเนอร์หน้าโฮมเพจของเว็บไซต์ และแอปพลิเคชัน',
        recommended_seller_app_Website: 'แนะนำสินค้าขายดีประจำร้านบนหน้าเว็บไซต์และแอปพลิเคชัน',
        line_oa_Broadcast: 'Line OA Broadcast',
        facebook_fan_page: 'Facebook Fan Page',
        let_EGGMall_help_you: 'ให้ EGGMall ช่วยคุณ',
        make_car_repair_easy: 'เราทำให้เรื่องซ่อมรถเป็นเรื่องง่าย',
        EGGMAll_comprehensive_spare_parts: 'EGGMall มีระบบจัดการร้านอะไหล่ และอู่ซ่อมรถ ครอบคลุมกลุ่มธุรกิจยานยนต์ ภายในแพลตฟอร์มเดียว',
        start_exchanging_today: 'เริ่มต้นซื้อขายวันนี้ที่ EGGMall',
        repair_workshop_management_system: 'ระบบบริหารจัดการอู่ซ่อมรถ',
        spare_parts_management_system: 'ระบบบริหารจัดการสินค้าสำหรับร้านอะไหล่',
        language: 'ภาษา',
        register: 'ลงทะเบียน',
        label_shopInfoShopName: 'ชื่อร้าน',
        contact_person: 'ชื่อผู้ติดต่อ',
        label_Email: 'อีเมล์',
        label_setPasswordBtn: 'ส่ง',
        ok: 'ตกลง',
        label_login: 'เข้าสู่ระบบ',
        go_to: 'ไปที่',
        label_orderTableMobileNumberPlaceholder: 'เบอร์มือถือ'
    },
    EGGRepair: {
        language_title: 'ภาษา',
        submit: 'ยืนยัน',
        header_title: 'บริหารจัดการอู่ของคุณได้สะดวกง่ายดาย',
        header_desc: 'แอพพลิเคชั่น EGG Repair เป็นระบบแอพพลิเคชั่น การเซอร์วิสและบริหารจัดการสำหรับอู่ซ่อมรถที่สมบูรณ์แบบ โดยจะครอบคลุมทุกกระบวนการทำงานของอู่ซ่อมรถทั้งหมด เช่น การออกใบเสร็จชำระเงิน,การบริหารจัดการลูกค้า, การจัดซื้อจัดหาอะไหล่, การจัดการคลังสินค้า, การจัดการเรื่องการเงิน',
        first_case_title: 'ท็อปไทล์ ออโต้แม็ก',
        first_case_desc: 'ท็อปไทล์ ออโต้แม็กเป็นธุรกิจครอบครัว เปิดให้บริการมาแล้วกว่า 10 ปี ปัจจุบันมีพนักงาน 6 คน และรองรับบริการได้พร้อมกัน 4 คัน ซึ่งงานหลักประกอบด้วย เปลี่ยนถ่ายน้ำมันเครื่อง 30%  บริการช่ว...',
        second_case_title: 'ปิ๊งเซอร์วิส',
        second_case_desc: 'ปิ๊งเซอร์วิส เปิดให้บริการมาแล้ว 3 ปีปัจจุบันมีพนักงานคนและเจ้าของอู่ที่ลงมือทำเองอีก 1 คน รองรับบริการได้พร้อมกัน 3 คันซึ่งงานหลักประกอบด้วยเปลี่ยนถ่ายน้ำมันเครื่อง10%บริการช่วงล่...',
        form_title: 'เริ่มต้นการทดลองใช้',
        main_title: 'ระบบเซอร์วิสและบริหารจัดการนี้สร้างมาสำหรับอู่ซ่อมรถทุกประเภท',
        more: 'ดูรายละเอียดเพิ่มเติม',
        valid_phone: 'รบกวนช่วยตรวจสอบเบอร์โทรศัพท์ของคุณอีกครั้ง',
        service_time: 'ระยะเวลาของการบริการ',
        success_message: 'การส่งข้อมูลสำหรับการทดลองใช้แอพพลิเคชั่นสำเร็จ ทางเราจะติดต่อกลับโดยเร็วที่สุดค่ะ',
        OK: 'ตกลง',
        download_tip: 'สำหรับ iPad เท่านั้น',
        loginEGGRepair: 'เข้าสู่ระบบ EGG Repair',
        workshopName: 'ชื่ออู่',
        employeeCount: 'จำนวนพนักงาน',
        contactPerson: 'ผู้ติดต่อ',
        mobilePhone: 'เบอร์มือถือ',
        verificationCode: 'รหัสยืนยัน',
        email: 'อีเมล์',
        send: 'ส่ง',
        resend: 'ส่งอีกครั้ง',
        optional: 'ตัวเลือก',
        checkFormatTips: 'โปรดตรวจสอบข้อมูล',
        reqiuredFieldTips: 'จำเป็นต้องใส่ช่องนี้',
        copy: 'คัดลอก',
        copied: 'คัดลอกแล้ว',
        connectInsurance: 'ติดต่อกับบริษัทประกันได้อย่างรวดเร็ว',
        name_input: 'เพิ่มชื่ออู่ของคุณ',
        contact_person: 'เพิ่มข้อมูลผู้ติดต่อ',
        phone_number: 'เพิ่มเบอร์โทรศัพท์ติดต่อ',
        email_input: 'เพิ่มอีเมลล์ของคุณ',
        aboutus: 'เกี่ยวกับเรา',
        aboutus_desc: 'แอพพลิเคชั่น EGG Repair เป็นผลิตภัณฑ์ของบริษัท วอทส์เอ้ก (ประเทศไทย) จำกัด <br/>แอพพลิเคชั่น EGG Repair เป็นระบบแอพพลิเคชั่น การเซอร์วิสและบริหารจัดการสำหรับอู่ซ่อมรถที่สมบูรณ์แบบ โดยจะครอบคลุมทุกกระบวนการทำงานของอู่ซ่อมรถทั้งหมด เช่น การออกใบเสร็จชำระเงิน,การบริหารจัดการลูกค้า, การจัดซื้อจัดหาอะไหล่, การจัดการคลังสินค้า, การจัดการเรื่องการเงิน',
        repairapp: 'รับ EGG Repair แอพพลิเคชั่น',
        eggmallapp: 'รับ EGG Mall แอพพลิเคชั่น',
        get_app: 'รับ {platform} แอพพลิเคชั่น',
        intro_title_1: 'ช่างไม่พอ งานล้น ทำไงถึงจะเพิ่มกำไรให้อู่ได้?',
        intro_content_1: 'Repair ช่วยให้คุณควบคุมงานบริการและช่างของคุณได้ด้วยภาพรวมการทำงานอย่างง่ายในหน้าเดียว คุณสามารถดูได้ว่าช่างคนไหนทำงานไหนบ้าง ช่างแต่ละคนทำไปแล้วกี่งาน งานไหนดูแล้วมีแนวโน้มเสร็จไม่ทัน คุณสามารถดูทุกอย่างได้ในหน้าแดชบอร์ด และวางปากกาและกระดานจดไปได้เลย.',
        intro_title_2: 'เหนื่อยมั้ยกับการต้องคอยจำรายละเอียดราคาแต่ละอย่างเพื่อบอกลูกค้า?',
        intro_content_2: 'ด้วยฟังก์ชั่นใบเสนอราคาคุณสามารถจำแนกค่าใช้จ่ายได้อย่างง่ายดาย อาทิเช่น ราคาอะไหล่ ค่าแรง โปรโมชั่น ส่วนลด ฯลฯ ทำให้อู่ดูมีความน่าเชื่อถือมากขึ้นและให้ลูกค้าแนะนำต่อกันได้.',
        intro_title_3: 'ลูกค้ามาถึงอู่แล้วจำไม่ได้ว่าคันนี้เคยทำอะไรไปแล้วบ้าง?',
        intro_content_3: 'คุณสามารถดูข้อมูลของลูกค้าทุกคนได้ รวมถึงประวัติการซ่อมด้วยเพื่อช่วยให้คุณวิเคราะห์ปัญหาใหม่ได้ง่ายขึ้นเพื่อแนะนำลูกค้าได้อย่างมีประสิทธิภาพและสร้างความเชื่อมั่นให้ลูกค้าไว้วางใจอู่ให้ดูแลต่อในระยะยาว.',
        intro_title_4: 'มีความยุ่งยากในการเช็คสต็อคหรือทำบัญชีรอบปี?',
        intro_content_4: 'เราสามารถทำให้มันง่ายและควบคุมได้ตั้งแต่ต้น ! การจัดซื้อ การทำสต็อค และตัวเลขบัญชีสามารถเชื่อมถึงกันและบันทึกในระบบอย่างปลอดภัยทุกขั้นตอน สิ่งที่คุณต้องทำก็แค่ดาวน์โหลดออกมาและเช็คแค่นั้น!',
        case_title: 'Tท็อปไทล์ ออโต้แม็ก',
        case_paragraph1: 'ท็อปไทล์ ออโต้แม็ก:EGG repair ช่วยให้เจ้าของอู่บริหารข้อมูลลูกค้าได้ง่ายขึ้น เช็คประวัติและวิเคราะห์อาการเสียได้รวดเร็วขึ้น รวมถึงการทำให้ภาพรวมของการบริการมีความเป็นมืออาชีพมากกว่าเดิมด้วยเทคโนโลยีสมัยใหม่ ',
        case_paratitle1: 'EGG Repair ช่วยเก็บข้อมูลลูกค้าได้มีประสิทธิภาพมากขึ้น',
        case_paragraph2: 'อนใช้ : ไม่ได้เก็บข้อมูลลูกค้าทั่วไปเลย ใช้แค่กระดาษจด หรือ excel สำหรับเก็บข้อมูลลูกค้าบริษัทเท่านั้น หลังใช้ ：สามารถเก็บช้อมูลลูกค้าทุกคันได้โดยละเอียด ซึ่งช่วยให้อู่เช็คประวัติได้ว่าลูกค้าแต่ละคันเคยซ่อมอะไรไปแล้วบ้าง ',
        case_paratitle2: 'EGG Repair ช่วยในการบริหารภาพรวมธุรกิจได้ง่ายขึ้น',
        case_paragraph3: 'จดใส่กระดาษว่าวันนี้มีลูกค้ามาทำอะไรบ้าง เบิกอะไรไปบ้างหรือบางครั้งก็ลง excel หลังใช้ : สามารถดูบัญชีรายรับและปริมาณงานรายวัน รวมถึงงานที่ช่างในอู่รับผิดชอบอีกด้วย',
        case_paragraph4: 'ฟังก์ชั่นที่ชื่นชอบ : การจัดเก็บข้อมูลลูกค้า (โดยเฉพาะกลุ่มฟลีทบริษัท) และประวัติการซ่อมของลูกค้าทุกคัน',
        case_paragraph5: 'EGG Repair คือได้ประโยชน์ในด้านเพิ่มประสิทธิภาพการทำงานโดยรวมของอู่ ทั้งเรื่องการลดระยะเวลาตรวจสอบปัญหาของรถ การเก็บข้อมูลลูกค้าโดยละเอียด เรื่องความแม่นยำมากขึ้นทางบัญชี และการสร้างความเชื่อมั่นให้ลูกค้าในการใช้บริการต่อเนื่อง',
        case2_title: 'ปิ๊งเซอร์วิส',
        case2_paragraph1: 'ปิ๊งเซอร์วิส：EGG repair ช่วยให้เจ้าของอู่เก็บข้อมูลลูกค้าได้ง่ายขึ้น และประหยัดเวลาในการหาประวัติการซ่อมได้รวดเร็วมากขึ้นหลายเท่า',
        case2_paratitle1: 'EGG Repair ช่วยเก็บข้อมูลลูกค้า ทำให้งานราบรื่นและรวดเร็วขึ้น',
        case2_paragraph2: 'ก่อนใช้ : ใช้แค่กระดาษจดเท่านั้น ทุกครั้งต้องมาคอยรื้อเพื่อดูว่ารถแต่ละคันเคยทำอะไรไปแล้วบ้าง หลังใช้：เก็บช้อมูลลูกค้าทุกคันได้โดยง่าย ใช้การค้นหาผ่านเลขทะเบียน ทำให้การวิเคราะห์ปัญหาและการให้คำแนะนำลูกค้ารวดเร็วขึ้นกว่าเดิมมาก ',
        case2_paratitle2: 'EGG Repair ช่วยนำเสนอและบริการลูกค้ามีประสิทธิภาพมากขึ้น',
        case2_paragraph3: 'ก่อนใช้：มีการใช้สินค้ามือถือบางรายการซึ่งยากต่อการลงบันทึกงานบริการและการรับประกัน หลังใช้ : สามารถเช็คประวัติงานบริการได้โดยง่าย และทำให้ลูกค้าเชื่อมั่นและกลับมาใช้บริการซ้ำได้',
        case2_paragraph4: 'ฟังก์ชั่นที่ชื่นชอบ : การจัดเก็บข้อมูลลูกค้า การสร้างงานบริการของตนเอง และประวัติการซ่อมของลูกค้าทุกคัน',
        case2_paragraph5: 'ภาพรวมของการใช้งาน EGG Repair คือลดระยะเวลาในการทำงานได้มากขึ้น การเก็บข้อมูลสินค้าและบริการต่างๆรวมถึงการสร้างงานบริการของตนเอง (custom made) ได้สะดวก สร้างความเชื่อถือให้ลูกค้าในด้านการบริการ และทำให้ลูกค้าใช้บริการซ้ำรวมถึงแนะนำต่อกันได้อีกด้วย',
        not_found_tips: 'ขออภัย หน้าที่คุณค้นหาไม่พร้อมใช้งาน',
        code_input: 'ใส่รหัส 4 ตัว',
        employee_input: 'ใส่จำนวนพนักงาน',
        sendVerificationCodeSuccess: 'รหัสยืนยันถูกส่งเข้ามือถือคุณแล้ว โปรดตรวจสอบ',
        submitSuccessfully: 'ส่งข้อมูลเรียบร้อย!',
        registSuccessTips: 'ระบบได้สร้างบัญชี EGG Repair เรียบร้อย ข้อมูลเข้าระบบตามด้านล่าง',
        registSuccessRemark: 'แผนกบริการลูกค้าของเราจะติดต่อคุณโดยเร็วที่สุดเพื่อให้บริการค่ะ',
        link: 'ลิ้งค์',
        initialPassword: 'รหัสตั้งต้น',
        startTrialRepair: 'เริ่มทดลองใช้ EGG Repair',
        registeredTitle: 'เบอร์มือถือของคุณถูกใช้ลงทะเบียนแล้ว',
        registeredTips: 'คุณสามารถเข้าสู่ระบบโดยตรงได้ทันที'
    }
};
