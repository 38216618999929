import axios from 'axios';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
});

/**
 * 请求拦截器
 */
service.interceptors.request.use((config) => {
    // 增加请求逻辑的内容写在这里，例如token
    return config;
}, (error) => {
    Promise.reject(error);
});

/**
 * 响应拦截器
 */
service.interceptors.response.use((response) => {
    const res = response.data;
    if (res.code !== '200') {
        const returnCode:any = ['1202', '10000001', '20049024'];
        if (returnCode.includes(res.code)) {
            return res;
        }

        return Promise.reject(new Error(res.message || 'Error'));
    } else {
        return res;
    }
}, (error) => {

    return Promise.reject(error);
});

export default service;
