import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css';

import {
    Carousel,
    CarouselItem
} from 'element-ui'

Vue.use(Carousel)
Vue.use(CarouselItem)
