export default {
    landingWeb: {
        ok: 'OK',
        submit: 'Submit',
        fieldRequired: 'This field is required',
        checkFormat: 'Please check the format',
        checkNumberFormat: 'Invalid cell phone number! Please check and try again!',
        verifySuccess: 'Verification code has been sent to your mobile, please check.'
    },
    home: {
        english: 'English',
        stressedFree: 'STRESSED FREE',
        homeCare: 'HOME CARE PROVIDER.',
        HelperApplyOnline: 'Helper Apply Online',
        provideDesc: 'We provide EXCELLENT QUALITY helpers with complete set of Knowledge and Skills bringing STRESS FREE HOME for all EMPLOYERS.',
        ismProvide: 'ISM provide professional service for all partner agency in different countries.',
        recruitment: 'Recruitment',
        professionalTraining: 'Professional Training',
        deployment: 'Deployment',
        recruitmentCase: 'RECRUITMENT',
        professionalTrainingCase: 'PROFESSIONAL TRAINING',
        trainingCenter: 'Training Center',
        enhancing: 'Enhancing professional confidence, skills and capabilities',
        customizedPrograms: 'Customized training programs',
        deploymentCase: 'DEPLOYMENT',
        trustedDesc: '"To become a trusted name and home care provider of choice in Asian Country, \n' + 'All families who only want the best home care through both our helpers and staff."',
        ourCoreValues: 'OUR CORE VALUES',
        contactUs: 'CONTACT US',
        Mandiri: 'PT. INDO SUMBER MANDIRI',
        squareMall: 'POINS SQUARE Mall',
        address: 'Jl. RA Kartini no. 1 - Cilandak, Lebak Bulus, Jakarta Selatan - 12440 Indonesia',
        footerAddress: '@ Copyright PT. INDO SUMBER MANDIRI',
        contactOurRecruiter: 'Contact Our Recruiter',
        copy: 'Copy',
        marketJob: 'Market Job',
        sourcing: 'Sourcing',
        screening: 'Screening',
        employerInterviews: 'Employer Interviews',
        confirmJobOrder: 'Confirm Job Order',
        signContract: 'Sign Contract',
        medicalCheck: 'Medical Check',
        documentation: 'Documentation',
        deployToOversea: 'Deploy to Oversea',
        integrity: 'Integrity',
        compassion: 'Compassion',
        accountability: 'Accountability',
        customerCommitment: 'Customer Commitment',
        copyTip: 'Please copy the link to open the application page from your mobile phone',
        copySuccess: 'copied successfully',
    },
    package: {
        packageFeature: 'EGG Alai Feature Packages',
        expandAll: 'Expand All',
        foldAll: 'Fold All',
        package: 'Package',
        monthly: 'Monthly',
        sixMonths: '6 Months',
        numberOfUsers: 'Number of users',
        storageSpace: 'Storage space',
        transactions: 'Transactions',
        dataFreeSupport: 'Data Free Support',
        maximumSkuUploaded: 'Maximum SKU uploaded in the Seller Shop',
        maximumSkuExport: 'Maximum SKU export and update (Batch Update) per one time',
        iamSkuFreeSelection: 'IAM SKU Free Selection from EGGMall database/ Data Migration on implementation up to 10,000 SKU',
        freeTransactionExport: 'Free Transaction Export including Customer & Supplier Master and all transactions up to 10,000 lines',
        basicFunction: 'Basic Function',
        basicFeature1: 'Display individual shop logo',
        basicFeature2: 'Import/export Product through Excel',
        basicFeature3: 'Authorization management',
        basicFeature4: 'Edit tracking log',
        basicFeature5: 'Create EGGMall SHOP automatically ',
        basicFeature6: 'Standard Document Printing with Fixed Layout',
        basicFeature7: 'Standard Barcode Label Printing with Fixed Layout',
        basicFeature8: 'Customized Document Print Setting',
        basicFeature9: 'Customized Barcode Label Printing Setting',
        counterSales: 'Counter Sales',
        counterFeature1: 'Product Search by Vin No or by vehicle type ',
        counterFeature2: 'Create quotation and order and manage deposit payment',
        counterFeature3: 'Clone sales order',
        counterFeature4: 'Return sales order',
        counterFeature5: 'Auto part data and price list available',
        counterFeature6: 'Customer credit management ',
        counterFeature7: 'Price ID setup',
        counterFeature8: 'Serial number/bar code generation',
        counterFeature9: 'Smart Scan Feature',
        mobileFeature1: 'Customer management',
        mobileFeature2: 'Mobile CRM ',
        mobileFeature3: 'Onsite ordering during sales visit',
        mobileFeature4: 'Sales force management',
        mobileFeature5: 'Customer royalty program',
        mobileFeature6: 'SMS notification to customer',
        purchasingLogistic: 'Purchasing & Logistic',
        inventoryManagementPurchasing: 'Inventory Management & Purchasing',
        purchaseFeature1: 'Purchase process',
        purchaseFeature2: 'Inventory management and inventory report',
        purchaseFeature3: 'Warehouse bin location',
        purchaseFeature4: '3PL logistic schedule booking',
        purchaseFeature5: '3PL logistic status check',
        dashboardFeature1: 'Business Overview Dashboard',
        dashboardFeature2: 'Profit & Loss report',
        dashboardFeature3: 'Create Collections Report',
        dashboardFeature4: 'Generate Business & Tax Reports',
        dashboardFeature5: 'Tax/Invoice Document',
        dashboardFeature6: 'Sales Summary Report',
        apiFeature1: 'Connect to other Marketplace (Lazada, Shopee...)',
        apiFeature2: 'Customer order connection via APP / Web',
        apiFeature3: 'Sales promotion setup',
        apiFeature4: '2C2P Payment gateway connection',
        apiFeature5: 'Connect with Facebook and google shop',
        apiFeature6: 'Facebook ads and google ads monthly campaign management',
        unlimited: 'Unlimited',
        dashboardFeature7: 'Automatic AP & AR and General Ledger',
        counterFeature10: 'Real time product details adjustment',
        counterFeature11: 'Easy stock adjustment from sales order',
        counterFeature12: 'Barcode and QR code smart scan feature',
        counterFeature13: 'Easy invoice, receipt and delivery note printing',
        counterFeature14: 'Volume discount for counter sales',
        counterFeature15: 'Basic Quotation printing',
        featurePackage: 'Feature Packages',
        addedOnFeature: 'Added On Feature',
        salesManagementName: 'Sales Management',
        salesManagementName1: 'Margin Control',
        salesManagementName2: 'Auto creation of OE SKU according to search result from EGG Product on SO creation',
        salesManagementName3: 'Auto creation of IAM according to search result from EGG Product on SO creation ',
        salesManagementName4: 'Customerized API connection from EMCS to EGGalai',
        salesManagementName5: 'Advanced insurance order processing features (car plate, remarks, status…)',
        salesManagementName6: 'Automatic store out API with connenction to shopee/Lazada shops',
        salesManagementName7: 'Advanced Batch Printing of AWB for Shopee/Lazada shops',
        salesManagementName8: 'QR code payment collection and settling ',
        salesManagementName9: 'Advance Quotation Management System',
        purchasingManagementName: 'Purchasing Management',
        purchasingManagement1: 'Purchase Plan',
        productInventoryManagementName: 'Product Management',
        productInventoryManagement1: 'Product History (SKU Level Purchasing & Sales  History)',
        productInventoryManagement2: 'OE SKU Listed Price Auto Update & Notification in Seller Shop SKU Database',
        productInventoryManagement3: 'Inventory data import & update feature ',
        productInventoryManagement4: 'Auto product creation from Lazada/Shopee',
        reportDashboardName: 'Report & Dashboard',
        reportDashboard1: 'Advanced export function including Customer & Supplier Master and all transaction (Every 10,000 lines)',
        reportDashboard2: 'Advanced Dashboard for business intelligence  ',
        additionalDataManagementName: 'Additional Data Management Charge',
        additionalDataManagement1: 'Additional IAM  Selection 2,000 SKU from EGGMall database ',
        additionalDataManagement2: 'Additional 2,000 SKU (20 Categories) migration to Seller database',
        contactTip: '· Contact us for more details and additional Fee for Add-On Features'
    },
    qanda: {
        title: 'Frequently Asked Questions'
    },
    EGGMall: {
        partner_center: 'Seller Center',
        set_up_shop: 'Set up Shop',
        welcome_text: 'Welcome to EGG Mall Partner Center',
        agree: 'Accept',
        not_agree: 'Don\'t Accept',
        data_usage_agreement: 'Data Usage Agreement',
        why_choose_us: 'Why choose us?',
        specific_description: 'With complete features and easy-to-manage Cloud technology Data analysis',
        benifits: 'Benefits',
        special_search_feature: 'Special Search Feature',
        search_by_vin: 'Product Search Feature by VIN and Vehicle Type',
        search_by_customer: 'Precision search reduced ordering mistakes by customers',
        digital_marketing_environment_title: 'Digital Marketing Environment',
        digital_marketing_environment: 'Digital Marketing Environment',
        ecosystem: 'Ecosystem',
        social_reels: 'Social Reels, Shopping Ads, Knowledge Center, CPAs Campaigns, Community Group',
        marketing_management: 'Marketing Management',
        facebook_page: 'Facebook page, Knowledge Center articles, Printed materials, SMS Plans, Press release, Digital advertisement',
        market_focusing_title: 'Market Focusing',
        market_focusing: 'Market Focusing',
        offline_sales: 'Offline sales to match industry needs: car user, owner, workshop chain, retailers, official dealers.',
        one_stop_service: 'One-Stop Service',
        e_commerce: 'E-Commerce and ERP (EGGAlai & EGGRepair) will consolidate the business. Help business owner do business better. Have more incentive for their business to join E-Commerce',
        frequently_asked_questions: 'Frequently Asked Questions',
        dialogue1: 'Do you know you can join EGGShop for free this year?',
        dialogue2: 'Really? No need to pay anything?',
        dialogue3: 'Exactly! Registration fee, annual fee, even the marketing package are free for this year.',
        dialogue4: 'Can you summarize all the benefits?',
        dialogue5: 'Market focusing, advertisement and one stop service to all new seller!',
        whats_egg_description: 'Established in 2017, Whats EGG is a leading B2B platform solution in ASEAN with focus on automotive aftermarket. We provide digital solution for workshop, retailer, wholesaler and vehicle insurance company.',
        product_links: 'Product Links',
        APP_download: 'APP Download',
        please_contact_us: 'Please Contact Us',
        learn_more: 'Learn More',
        generate_solution: 'Generate Solution',
        set_up_shop_title: 'I am interested, please contact me.',
        mobileNumberPlaceholder: 'Enter your mobile number',
        verifyCodePlaceholder: 'Enter 4-digit code',
        shopNamePlaceholder: 'Enter your shop name',
        shopAddressPlaceholder: 'Enter street and house number',
        emailPlaceholder: 'Enter your Email',
        verifyCode: 'Verification Code',
        shopAddress: 'Shop Address',
        checkFormat: 'Please check the format',
        getCode: 'Get Code',
        submitIntro: 'Your information has been submitted successfully, our staff will contact you as soon as possible.',
        submitSuccess: 'Submit Successfully!',
        contactPersonPlaceholder: 'Enter your contact person',
        send_code_success: 'Verification code has been sent to your mobile, please check.',
        banner_title: 'Expand Your Sale Opportunity in Digitalization Era with EGGMall',
        banner_desc_1: 'Grow business to an online market 24/7',
        banner_desc_2: 'Reach B2B automotive market accurately',
        banner_desc_3: 'Digitalize parts search by SKU search engine ',
        contact_us_to_create_shop: 'Contact Us to Create Shop',
        why_choose_EGGMall: 'Why Choose EGGMall?',
        how_to_apply: 'How to Apply',
        it_support: 'IT Support',
        marketing_support: 'Marketing Support',
        seller_benefits: 'Seller’s Benefits',
        commission_free: 'Commission start from 0%',
        register_free: 'Register fee 0 Baht',
        special_transferred_seller: 'Special for transferred seller, get free 5000 Marketing',
        annual_fee: 'Annual fee 0 Baht',
        special_settlement_fee: '*special settlement fee rate',
        monthly_promotional_campaign: 'Monthly promotional campaign',
        platform_promotional_cash: 'Platform promotional cash back and sale subsidy up to 4%',
        applicable_under_TC: '**Applicable under T&C of the company issued on 2023 only',
        easy_to_apply: 'EASY to Apply',
        fill_the_form: '1.Fill the form',
        waiting_contact: '2.Waiting EGGMall contact you.',
        lifetimeservice_support: 'Selling on EGGMall will have the lifetime after sale service support.',
        east_to_transfer_data: 'EASY to Transfer Data from Shopee/Lazada to EGGMall',
        data_management_team_support: 'Data Management Team Support',
        reach_the_right_target: 'Reach the right target',
        promote_your_shop_via_EGGMall: 'and promote your shop via EGGMall app and website',
        enlarge_your_customer: 'Enlarge your customer',
        base_by_social_media: 'base by Social media Promotion',
        year_round_campaign: 'Year round campaign',
        sale_booster: 'sale booster',
        seller_on_board_promotion: 'Seller on Board Promotion Today!',
        app_open_pop_up: 'App-Open Pop up App',
        homepage_banner_app_Website: 'Homepage Banner App/Website',
        recommended_seller_app_Website: 'Recommended Seller Hot Items App/Website',
        line_oa_Broadcast: 'Line OA Broadcast',
        facebook_fan_page: 'Facebook Fan Page',
        let_EGGMall_help_you: 'Let EGGMall Help you',
        make_car_repair_easy: 'We Make Car Repair EASY!',
        EGGMAll_comprehensive_spare_parts: 'EGGMAll, comprehensive spare parts retailers and workshop management system in one platform.',
        start_exchanging_today: 'Start Exchanging Today at EGGMall',
        repair_workshop_management_system: 'Car Repair workshop Management System',
        spare_parts_management_system: 'Spare parts Management System',
        language: 'Language',
        register: 'Register',
        label_shopInfoShopName: 'Shop Name',
        contact_person: 'Contact Person',
        label_Email: 'Email',
        label_setPasswordBtn: 'Submit',
        ok: 'OK',
        label_login: 'Login',
        go_to: 'Go to',
        label_orderTableMobileNumberPlaceholder: 'Mobile Number'
    },
    EGGRepair: {
        language_title: 'Language',
        submit: 'Submit',
        main_title: 'A cloud system, built for all types of workshop',
        first_case_title: 'Toptile Automax',
        first_case_desc: 'Toptile Automax is a family-run shop which operate for 10 years. Currently, it has 6 staffs and 4 bays. 3 0% job in this shop is changing oil, 30% is repairing brake and suspension, other fluid…',
        second_case_title: 'Ping Service',
        second_case_desc: 'Ping Service operated for 3 years. Currently it has 2 staffs plus owner that can service 3 cars at the same time. Jobs for this shop; Oil change 10%, suspension and brake 30%, transmissio…',
        more: 'See more',
        form_title: 'Start Your Trial',
        header_title: 'Manage Your Workshop Easily!',
        header_desc: 'EGG Repair is a complete cloud system for workshop, which includes car repair billing, customer management, spare parts purchase, warehouse management, finance management.',
        valid_phone: 'Please double check format of phone number',
        service_time: 'Service time',
        success_message: 'Your trial application was submitted successfully and we will contact you as soon as possible',
        OK: 'OK',
        download_tip: 'Only For iPad',
        loginEGGRepair: 'Login EGG Repair',
        workshopName: 'Workshop Name',
        employeeCount: 'No. of Employees',
        contactPerson: 'Contact Person',
        mobilePhone: 'Mobile Phone',
        verificationCode: 'Verification Code',
        email: 'Email',
        send: 'Send',
        resend: 'Resend',
        optional: 'Optional',
        checkFormatTips: 'Please check the format',
        checkPhonenumber: 'Invalid cell phone number! Please check and try again!',
        reqiuredFieldTips: 'This field is required',
        copy: 'Copy',
        copied: 'Copied',
        connectInsurance: 'Connect With Insurance',
        name_input: 'Enter your workshop name',
        contact_person: 'Enter contact person',
        phone_number: 'Enter your phone number',
        email_input: 'Enter your email',
        aboutus: 'About Us',
        aboutus_desc: 'EGG Repair is a product provided by EGG Mall.<br/>EGG Repair is a complete cloud system for workshop, which includes car repair billing, customer management, spare parts purchase, warehouse management, finance management.',
        repairapp: 'Get EGG Repair APP',
        eggmallapp: 'Get EGG Mall APP',
        get_app: 'Get {platform} APP',
        intro_title_1: 'Limited employees, How to improve the profit? ',
        intro_content_1: 'Repair provides you total control of your staff and jobs. By optimizing and visualizing the entire workflow, you can manage your operation on one page. Which job is doing by which technician? how many jobs done by each technician? Which job has the trend to delay? Throw away your blackboard! All these questions will be answered in the Repair Dashboard!',
        intro_title_2: 'Tired to answer customer\'s questions about price structure?',
        intro_content_2: 'Precise quotation management allows customers to see every part of the cost, like parts cost, service charge, tax, promotion, discount...Increasing the trust of each customer, they will recommend your workshop to more customers.',
        intro_title_3: 'What service should recommend to customers?',
        intro_content_3: 'Clear records of all customers, customer characteristics, historical maintenance records can be found and related. Using these data to customize services and recommend it to your customers which is more professional and trustful!',
        intro_title_4: 'Hard to check inventory and finances at year-end? ',
        intro_content_4: 'We can make it clear in the beginning! The number of purchases, warehouse storage, and financial data is connected and saved in EGG Repair at each transaction level. What you need to do is downloading the report and confirm the final figures!',
        case_title: 'Toptile Automax',
        case_paragraph1: 'Toptile Automax:EGG repair helps owner to improve customer management, history record check to speed up problem investigation. It also makes workshop look more professional by applying new technology for customers. ',
        case_paratitle1: 'EGG Repair helps to manage customer efficiently',
        case_paragraph2: 'Before : Never collect personal car customers, only do excel for some fleet. Now Collect all customers information and repair record that helps them to investigate problem faster and be able to recommend customer the right solution professionally.',
        case_paratitle2: 'EGG Repair helps overall business review easier',
        case_paragraph3: 'Before : Most of time write on paper, sometimes note in excel or memory Now : Can see daily service and income as well as mechanic information',
        case_paragraph4: 'Most interested module: Customer data management (especially for fleet customer) and history check.',
        case_paragraph5: 'EGG Repair improves the whole efficiency of workshop and save a lot time of investigate problems, data collection and tracking as well as improve customer’s trust.',
        case2_title: 'Ping Service',
        case2_paragraph1: 'EGG repair helps owner to create custom jobs and store customer data easily that help him to save much more time',
        case2_paratitle1: 'EGG Repair helps collecting customer data that makes overall jobs faster and more smooth ',
        case2_paragraph2: 'Before : Use only paper writing, every time need to spend time to find history of each customer Now：Much easy to input data and can find history by just using car plat no. that improve more speed of investigation and service. ',
        case2_paratitle2: 'EGG Repair helps to do present and service more professionally and have more trust',
        case2_paragraph3: 'Before：Difficult to do second-hand service as it is hard to remember and calculate mileage of warranty Now : Can easily track record and show the clear details to customer ',
        case2_paragraph4: 'Most interested module: Customer data management, customize service creation and history check for every customers.',
        case2_paragraph5: 'Overall review of EGG Repair, it shortens time of service from data management and history record. It also helps shop to manage some customize job that owner can create by himself and present to customer that makes dirty shop looks more professional and get more repeated customers.',
        not_found_tips: 'Sorry, the page you visited does not exist.',
        code_input: 'Enter 4-digit code',
        employee_input: 'Enter No. of Employees',
        sendVerificationCodeSuccess: 'Verification code has been sent to your mobile, please check.',
        submitSuccessfully: 'Submit Successfully!',
        registSuccessTips: 'We have created EGG Repair account for you, Login detail as below.',
        registSuccessRemark: 'Our customer service will contact you soon to provide a better service!',
        link: 'Link',
        initialPassword: 'Initial Password',
        startTrialRepair: 'Start Trial to EGG Repair',
        registeredTitle: 'Your mobile phone has already been registered.',
        registeredTips: 'You can login directly',
        employeeNumberTips: 'No. of employee must be greater than 0.',
        sendCodeErrTips: 'Send failed, please try again！',
        getCode: 'Get Code'
    },
    ourBusiness: "Our Business",
    ourService: "Our Service",
    gallery: "Gallery",
    aboutUs: "About Us",
    text1: "We provide EXCELLENT QUALITY helpers with complete set of Knowledge and Skills bringing STRESS FREE HOME for all EMPLOYERS",
    recruitment: "Recruitment",
    professionalTraining: "Professional Training",
    deployment: "Deployment",
    jobScope: "Job Scope",
    text2: "PHATES provide professional service for all partner agency in different countries.",
    domesticHelper: "Domestic Helper",
    careGiver: "Care Giver",
    tutoringChild: "Tutoring & Child Car",
    driver: "Driver",
    factoryWorker: "Factory Worker",
    otherSkilledWorker: "Other Skilled Worker",
    singapore: "Singapore",
    hongKong: "Hong Kong",
    taiwan: "Taiwan",
    malaysia: "Malaysia",
    japan: "Japan",
    middleEast: "Middle East",
    applyOnline: "Apply Online",
    text3: "We actively promote job openings to reach a wide and relevant audience. \nUtilizing job portals, social media, email campaigns, and content marketing, we attract high-quality candidates best suited for your positions.",
    text4: "Our training center is dedicated to equipping job seekers with the skills and knowledge they need to excel in their careers.\nWe offer a range of courses and training programs designed to enhance employability, improve job performance, and support career advancement.",
    trainingScope: "TRAINING scope",
    mindset: "Mindset",
    text5: "Helper's Orientation In working country including their Daily Schedules",
    dormitoryHouseRules: "Dormitory and House Rules",
    interviewOri: "Interview Orientation",
    text6: "Attitude, Behaviour and value",
    communication: "Communication",
    text7: "Singapore regulation and law",
    chCulture: "Chinese Culture",
    basicManners: "Basic Manners",
    homeSafety: "Home Safety",
    housework: "Housework",
    text8: "General house work-how to work properly",
    text9: "Home organization-Fridge,closet, kitchen ect",
    bedSetting: "Bed Setting",
    tableSetting: "Table Setting",
    wli_label: "Washing/laundry/Iroining",
    text10: "Cleaning , Vacuum and Polishing",
    text11: "House cleaning-kitchen, toliet ect",
    carWashing: "Car Washing",
    cooking: "Cooking",
    foodPreparation: "Food preparation",
    chinaCooking: "Chinese cooking",
    westenCooking: "Western cooking",
    Childcare: "Child care",
    Infantcare: "Infant care",
    Elderlycare: "Elderly care",
    trustedDesc: 'To become a trusted name and home care provider of choice in Asian Country, All families who only want the best home care through both our helpers and staff.',
    text12: "We uphold the highest standards of integrity in all our actions. Our commitment to honesty and transparency ensures trust and reliability in every interaction with our clients, candidates, and partners.",
    text13: "We approach every interaction with empathy and understanding. By showing genuine care and concern for the well-being of our clients and candidates, we build meaningful relationships and create a supportive environment.",
    text14: "We take responsibility for our actions and deliver on our promises. Our commitment to accountability ensures that we meet our obligations and uphold the trust placed in us by our clients and candidates.",
    text15: "Our clients and candidates are at the heart of everything we do. We are dedicated to understanding their needs and providing personalized solutions that exceed their expectations. Our unwavering commitment to customer satisfaction drives us to go above and beyond in every service we offer.",
    sourceText: "Our sourcing service identifies and attracts top talent through an extensive network, robust database, strategic social media outreach, and a strong referral program, ensuring we find the right fit for your needs",
    screentText: "We ensure only the most qualified candidates move forward through comprehensive resume reviews, initial phone interviews, skill assessments, and background checks, guaranteeing candidates meet your requirements.",
    employerInterText: "We coordinate and facilitate seamless interviews, assisting with scheduling, candidate preparation, and gathering feedback from both parties, refining the selection process and enhancing future recruitment.",
    confirmJobOrderText: "We confirm all job details and requirements before proceeding, obtaining detailed job descriptions, clarifying roles and expectations, and ensuring mutual agreement on terms and conditions to set clear expectations.",
    signContractText: "We manage the final hiring steps by preparing and facilitating employment contract signing, ensuring both parties agree on terms, and assisting with onboarding for a smooth transition into the new role."
};
